import React, { useEffect, useState } from "react";
import { Sankey, Tooltip, ResponsiveContainer } from "recharts";
import { Typography, Box, FormControl, InputLabel, MenuItem, Select, CircularProgress } from "@mui/material";

const EIXO_Y = ["CC", "EVASÃO", "EC", "SI"];
const CORES = {
  EVASÃO: "#FF0000", // Vermelho
  CC: "#0000FF", // Azul
  EC: "#00FF00", // Verde
  SI: "#FFFF00", // Amarelo
};

const atribuirCor = (nome) => {
  if (nome.includes("Evasão")) return CORES.EVASÃO;
  if (nome.includes("CIÊNCIA DA COMPUTAÇÃO")) return CORES.CC;
  if (nome.includes("ENGENHARIA DE COMPUTAÇÃO")) return CORES.EC;
  if (nome.includes("SISTEMAS DE INFORMAÇÃO")) return CORES.SI;
  return "#CCCCCC"; // Cor padrão para casos não mapeados
};

const CustomNode = ({ x, y, width, height, payload }) => {
  const cor = payload.color || "#000000";
  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={cor} stroke="#000" strokeWidth={1} rx={4} ry={4} />
    </g>
  );
};

const Chart = ({ dadosSankey, periodos }) => (
    <Box sx={{ width: "100%", mt: 1, position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: "100px",
          left: "10px",
          height: "calc(50%)", 
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {EIXO_Y.map((curso, index) => (
          <Typography key={index} variant="body1" sx={{ textAlign: "right", marginBottom: 0 }}>
            {curso}
          </Typography>
        ))}
      </Box>
  
      {/* Título */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Typography variant="h6">Fluxo de Estudantes por Curso e Período</Typography>
      </Box>
  
      {/* Gráfico */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", width: "100%" }}>
        <ResponsiveContainer width="80%" height={300}>
          {dadosSankey && dadosSankey.links && dadosSankey.nodes ? (
            <Sankey
              data={dadosSankey}
              node={<CustomNode />}
              link={{
                strokeWidth: 2,
                fillOpacity: 0.5,
              }}
              nodePadding={40}
              margin={{ top: 10, bottom: 50 }}
            >
              <Tooltip
                formatter={(value, name, props) => {
                  const { payload } = props;
                  if (payload?.source && payload?.target) {
                    return [
                      `De: ${payload.source.name} para ${payload.target.name}`,
                      `Valor: ${value}`,
                    ];
                  }
                  return value;
                }}
                contentStyle={{
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </Sankey>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Nenhum dado disponível.
            </Typography>
          )}
        </ResponsiveContainer>
      </Box>
  
      {/* Alinhamento do eixo X */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        {periodos.map((periodo, index) => (
          <Typography key={index} variant="body1" sx={{ textAlign: "center" }}>
            {periodo}
          </Typography>
        ))}
      </Box>
    </Box>
  );
  
const App = () => {
  const [dadosSankey, setDadosSankey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anoInicio, setAnoInicio] = useState("2018.2");
  const [anoFim, setAnoFim] = useState("2021.2");
  const anos = ["2014.1", "2014.2", "2015.1", "2015.2", "2016.1", "2016.2", "2017.1", "2017.2", "2018.1", "2018.2", "2019.1", "2019.2", "2020.1", "2020.2", "2021.1", "2021.2"];
  const [periodos, setPeriodos] = useState([]);

  useEffect(() => {
    const fetchSankeyData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://dev2.icausas.live/sankey?start_period=${anoInicio}&end_period=${anoFim}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Data received:", data);

        // Adicionar cores aos nodes
        const nodesComCores = data.nodes.map((node) => ({
          ...node,
          color: atribuirCor(node.name),
        }));
        setDadosSankey({ ...data, nodes: nodesComCores });

        const startIndex = anos.indexOf(anoInicio);
        const endIndex = anos.indexOf(anoFim);
        setPeriodos(anos.slice(startIndex, endIndex + 1));
      } catch (error) {
        console.error("Erro ao buscar dados do Sankey:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSankeyData();
  }, [anoInicio, anoFim]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Início</InputLabel>
          <Select value={anoInicio} onChange={(e) => setAnoInicio(e.target.value)} label="Início">
            {anos.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Fim</InputLabel>
          <Select value={anoFim} onChange={(e) => setAnoFim(e.target.value)} label="Fim">
            {anos
              .filter((item) => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 0 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Chart dadosSankey={dadosSankey} periodos={periodos} />
      )}
    </Box>
  );
};

export default App;
