import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { Box, Typography } from '@mui/material';

const RenderChart = ({ scatterData, curso }) => {
  const series = [
    {
      type: 'boxPlot',
      data: scatterData.map(item => ({
        x: curso.nome,
        y: [item.minimo, item.q1, item.mediana, item.q3, item.maximo]
      }))
    }
  ];

  console.log(`Dados para o gráfico do curso ${curso.nome}:`, series[0].data);

  const chartOptions = {
    chart: {
      type: 'boxPlot',
      toolbar: {
        show: false
      }
    },
    title: {
      text: curso.nome,
      align: 'center',
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#5C4742',
          lower: '#A5978B',
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        return `<div>
          <span>Máximo: <strong>${data.y[4]}</strong></span><br/>
          <span>Q1: <strong>${data.y[1]}</strong></span><br/>
          <span>Mediana: <strong>${data.y[2]}</strong></span><br/>
          <span>Q3: <strong>${data.y[3]}</strong></span><br/>
          <span>Mínimo: <strong>${data.y[0]}</strong></span><br/>
        </div>`;
      },
    },
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={series}
      type="boxPlot"
      width="100%"
      height={350}
    />
  );
};

const BoxPlot = () => {
  const cursos = [
    { nome: 'cc', valor: 1 },
    { nome: 'ec', valor: 2 },
    { nome: 'si', valor: 3 }
  ];
  const [scatterData, setScatterData] = useState({ cc: [], ec: [], si: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataPromises = cursos.map(curso =>
          axios.get(`https://api.icausas.live/api/teste_student/student/boxplot/${curso.valor}/`)
        );
        const responses = await Promise.all(dataPromises);
        const newScatterData = responses.reduce((acc, response, index) => {
          acc[cursos[index].nome] = response.data;
          return acc;
        }, {});
        setScatterData(newScatterData);
        console.log("Dados recebidos da API:", newScatterData);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
        <Typography variant="h6">
        Distribuição de média por curso
</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {cursos.map(curso => (
          <Box key={curso.valor} sx={{ margin: '0 5px', width: '33%' }}>
            {scatterData[curso.nome].length > 0 ? (
              <RenderChart scatterData={scatterData[curso.nome]} curso={curso} />
            ) : (
              <div>Carregando dados para o curso {curso.nome}...</div>
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default BoxPlot;
